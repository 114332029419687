<template>
    <div v-show="!isHidden">
        <el-steps finish-status="success" :active="active" align-center>
            <el-step v-for="(item, index) in stages" :key="index" :title="item.name"
                     :description="item.approval_date ? $moment(item.approval_date).format('DD.MM.Y H:mm:ss') : null"></el-step>
        </el-steps>
    </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

export default {
  name: 'a-approval',
  inject: {
    getRegistryRecordId: { default: () => {} },
    getRegistryId: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin, VisibleMixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    approvalId: {
      type: String,
      description: 'approval_id'
    }
  },
  data () {
    return {
      stages: [],
      localModel: this.getModel()
    }
  },
  computed: {
    active () {
      let active = -1
      this.stages.forEach((item, index) => {
        if (item.status) {
          active = index + 1
        }
      })
      if (active === -1) {
        if ((this.stages[0] || {}).is_running) {
          active = 0
        }
      }
      return active
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    localModel (value) {
      if (value.id && this.getRegistryRecordId() && this.getRegistryId()) {
        this.loadData()
      }
    }
  },
  mounted () {
    if (this.approvalId && this.getRegistryRecordId() && this.getRegistryId()) {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.getRegistryId()}/records/${this.getRegistryRecordId()}/approvals/${this.approvalId}`)
      this.stages = data.data
    }
  }
}
</script>

<style scoped>

</style>
